import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import QuoteForm from '../forms/QuoteForm'
import Seo from '../components/Seo'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'

const homepage = ({ data, pageContext }) => {
  const { wpPage: page } = data
  const quoteFormHero = page?.quoteFormHero

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`[{
              "@context": "https://schema.org",
              "@type": "AutoDealer",
              "name": "D&M Leasing",
              "image": "https://www.dmautoleasing.com/dm-white.svg",
              "@id": "https://schema.org/AutoDealer",
              "url": "https://www.dmautoleasing.com/",
              "telephone": "",
              "address": {
                "@type": "PostalAddress",
                "addressRegion": "TX",
                "addressCountry": "US"
              } ,
              "sameAs": [
                "https://www.facebook.com/dmautoleasing/",
                "https://www.instagram.com/dmleasing/",
                "https://www.pinterest.com/dmleasing/"
              ]             
          }]`}
        </script>
      </Helmet>
      <div
        className="home-page transition-all"
        dangerouslySetInnerHTML={{ __html: page.content }}
      />
      <div className="quote-hero md:h-96 bg-cover bg-center w-full flex md:justify-end relative items-center md:px-20 mb-8 md:mb-0">
        <img
          className="hidden md:block w-full absolute inset-0 object-cover h-full"
          src={quoteFormHero?.backgroundimage?.mediaItemUrl}
        />
        <div className="z-10 w-full quote-form md:max-w-2xl">
          <div className="bg-brand-green py-5 w-full text-center">
            <h3 className="mb-0">{quoteFormHero.formHeader}</h3>
          </div>
          <img
            className="md:hidden w-full object-cover h-64"
            src={quoteFormHero?.backgroundimage?.mediaItemUrl}
          />
          <QuoteForm />
        </div>
      </div>
    </Layout>
  )
}
export default homepage

export const pageQuery = graphql`
  query HomepageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      quoteFormHero {
        formHeader
        backgroundimage {
          id
          mediaItemUrl
        }
      }
    }
  }
`
